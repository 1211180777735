import React from "react";

const ToggleSwitch = ({ label, checked, onChange }) => {
  return (
    <label
      htmlFor="default-toggle"
      className="inline-flex relative items-center cursor-pointer"
    >
      <input
        onChange={(e) => onChange(e.target.checked)}
        id="default-toggle"
        type="checkbox"
        value={checked}
        checked={checked}
        className="sr-only peer"
      />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-shamrock"></div>
      <span className="ml-3 text-sm font-medium text-gray-600">{label}</span>
    </label>
  );
};

export default ToggleSwitch;
