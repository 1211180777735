import classNames from "classnames";
import React, { useState } from "react";
import ToggleSwitch from "./common/ToggleSwitch";

const options = [
  {
    name: "Essential",
    description: () => (
      <ul>
        <li>PMS Integration</li>
        <li>Mobile check-in</li>
        <li>I.D. Capture</li>
        <li>Payment Tokenization</li>
        <li>Registration</li>
      </ul>
    ),
    annualMonthlyCost: "$4.50",
    monthlyCost: "$5",
    frequency: "/room/month",
  },
  {
    name: "Journey",
    description: () => (
      <ul>
        <li>
          Everything in <strong>Essential</strong>
        </li>
        <li>I.D. Verification</li>
        <li>Digital Key</li>
        <li>Climate / HVAC Integration</li>
        <li>Lighting Integration</li>
        <li>Energy Management</li>
        <li>2-way Chat</li>
        <li>Room upsell</li>
        <li>Late check-out upsell</li>
      </ul>
    ),
    annualMonthlyCost: "$9.00",
    monthlyCost: "$10",
    frequency: "/room/month",
  },
  {
    name: "Autopilot",
    description: () => (
      <ul>
        <li>
          Everything in <strong>Journey</strong>
        </li>
        <li>Media / TV integration</li>
        <li>Shades integration</li>
        <li>Push Marketing</li>
        <li>Room automations</li>
        <li>Guest survey</li>
        <li>Point of sale integration</li>
      </ul>
    ),
    annualMonthlyCost: "$13.50",
    monthlyCost: "$15",
    frequency: "/room/month",
    highlight: true,
  },
  {
    name: "Custom",
    description: () => (
      <ul>
        <li>
          Everything in <strong>Autopilot</strong>
        </li>
        <li>A.I. Chat</li>
        <li>Voice control</li>
        <li>Crypto payments</li>
        <li>Guest tipping</li>
        <li>Payments</li>
        <li>Valet</li>
        <li>Experiences</li>
      </ul>
    ),
    annualMonthlyCost: "Let's Talk",
    monthlyCost: "Let's Talk",
    frequency: "",
  },
];

const PricingOption = ({
  name,
  description: Description,
  cost,
  frequency,
  highlight,
}) => {
  const cx = classNames(
    "flex-1 drop-shadow-lg min-h-96 px-6 py-8 mx-3 rounded-md mb-8 lg:mb-0 flex flex-col justify-between",
    {
      "bg-shamrock text-white": highlight,
      "bg-white text-gray-600": !highlight,
    }
  );

  //convert USD currency to number
  /* eslint-disable no-useless-escape */
  const dailyCost = Number(cost.replace(/[^0-9\.]+/g, "")) / 31;

  // round to 2 decimal places
  const dailyCostRounded = (Math.round(dailyCost * 100) / 100).toFixed(2);

  return (
    <div className={cx}>
      <h4 className="text-xl font-semibold mb-4">{name}</h4>
      <div className="font-light flex-2 mb-4">
        <Description />
      </div>
      <p
        className={classNames("text-xl mt-auto font-semibold mb-2", {
          "text-white": highlight,
          "text-gray-600": !highlight,
        })}
      >
        <span
          className={classNames("text-2xl", {
            "text-white": highlight,
            "text-shamrock": !highlight,
          })}
        >
          {cost}
        </span>{" "}
        {frequency}
      </p>
      {dailyCostRounded > 0 && (
        <span
          className={classNames("text-sm italic", {
            "text-gray-400": !highlight,
            "text-white": highlight,
          })}
        >
          Only ${dailyCostRounded}/room/day
        </span>
      )}
    </div>
  );
};

const Pricing = () => {
  const [pricing, setPricing] = useState("monthly");
  const label = pricing === "annual" ? "Annual Pricing" : "Monthly Pricing";
  return (
    <>
      <div className="flex flex-col items-center justify-center mb-4">
        <ToggleSwitch
          onChange={(checked) => setPricing(checked ? "annual" : "monthly")}
          label={label}
          checked={pricing === "annual"}
        />
      </div>
      <div className="flex flex-col lg:flex-row mx-auto">
        {options.map((option) => {
          const { name, monthlyCost, annualMonthlyCost, ...optionData } =
            option;
          return (
            <PricingOption
              key={name}
              name={name}
              cost={pricing === "annual" ? annualMonthlyCost : monthlyCost}
              {...optionData}
            />
          );
        })}
      </div>
    </>
  );
};

export default Pricing;
