import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import DemoButton from "../components/common/DemoButton";
import Button from "../components/common/Button";
import Layout from "../components/Layout";
import MGMLogo from "../images/mgm.inline.svg";
import NobuLogo from "../images/nobu.inline.svg";
import CaesarsLogo from "../images/caesars.inline.svg";
import HeartIcon from "../images/heart.inline.svg";
import ThumbsUp from "../images/thumbsup.inline.svg";
import Bolt from "../images/bolt.inline.svg";
import AppFeatures from "../components/AppFeatures";
import SectionHeading from "../components/SectionHeading";
import Pricing from "../components/Pricing";
import Gradient from "../components/common/Gradient";
import PhoneVideo from "../assets/kohost-iphone-animation-full.mp4";
import ContactModal from "../components/ContactModal";
import ContactForm from "../components/ContactForm";
import IntegrationGrid from "../components/IntegrationGrid";

// markup
const IndexPage = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Helmet>
        <title>Guest Experience Software for Hospitality | Kohost</title>
      </Helmet>
      <Layout>
        <section className="text-center text-base text-gray-600 container mx-auto">
          <p className="mb-8">Book a demo with us!</p>
          <h1 className="text-6xl font-bold mb-8 text-black">
            Guest Experience Platform for <Gradient>Hospitality</Gradient>
          </h1>
          <p className="mb-24">
            <DemoButton />
          </p>
          <h2 className="font-light text-2xl text-black mb-4">
            Powering Future Forward Hospitality Organizations
          </h2>
          <p className="mb-16">
            Join our users in saving hundreds of hours of labor costs and
            increase revenue by automating the guest journey.
          </p>

          <div className="flex flex-col lg:flex-row items-center justify-between mx-auto max-w-5xl">
            <div className="flex-1 px-4 mb-8 lg:mb-0 grayscale hover:grayscale-0 transition-all ease-in-out duration-300">
              <StaticImage
                src={"../images/ti-logo.png"}
                className="w-auto mx-auto"
                alt="Treasure Island Las Vegas"
              />
            </div>
            <div className="flex-1 px-4 mb-8 lg:mb-0">
              <MGMLogo className="w-auto h-9 mx-auto grayscale hover:grayscale-0 transition-all ease-in-out duration-300" />
            </div>
            <div className="flex-1 px-4 mb-8 lg:mb-0">
              <StaticImage
                src={"../images/maj-logo.png"}
                className="w-auto mx-auto"
                alt="Maj Hotel"
              />
            </div>
            <div className="flex-1 px-4 mb-8 lg:mb-0">
              <NobuLogo className="w-auto h-9 mx-auto grayscale hover:grayscale-0 transition-all ease-in-out duration-300" />
            </div>
            <div className="flex-1 px-4 mb-8 lg:mb-0">
              <CaesarsLogo className="w-auto h-9 mx-auto grayscale hover:grayscale-0 transition-all ease-in-out duration-300" />
            </div>
          </div>
        </section>

        <section className="text-center w-full mx-auto my-16 bg-black">
          <video
            className="mx-auto w-auto h-auto"
            muted
            autoPlay
            loop
            playsInline
            src={PhoneVideo}
            type="video/mp4"
          />
        </section>

        <section className="container mx-auto max-w-6xl mb-32">
          <AppFeatures />
        </section>

        <section className="container max-w-6xl mx-auto mb-32">
          <SectionHeading>One tool to save you time</SectionHeading>
          <p className="text-xl text-center text-gray-600 font-light mb-16">
            Flexible monthly pricing for hotels of all sizes.
          </p>
          <div className="mb-8">
            <Pricing />
            <p className="text-center italic text-gray-600 mt-16">
              One-time integration and onboarding fees are priced per property.{" "}
              <br />
              Minimum monthly pricing applies at $499.00
            </p>
          </div>

          <p className="text-center">
            <Button onClick={() => setOpenModal(true)}>Save me time</Button>
          </p>
        </section>
        <section className="container px-4 max-w-6xl mx-auto mb-32">
          <SectionHeading>You're in good company</SectionHeading>

          <div className="w-full max-w-4xl mx-auto rounded-md text-lg text-gray-600 bg-white drop-shadow-md p-8 mb-16">
            <span className="bg-tertiary text-white rounded-full w-12 h-12 flex items-center justify-center drop-shadow-md shadow-purple-300 mb-4">
              <HeartIcon className="fill-white w-6 h-6" />
            </span>

            <p className="mb-8">
              "We use Kohost for the entire guest journey, including mobile
              check-in, digital key, room controllers, messaging and marketing.
              We love it."
            </p>

            <p>– Monika Maj, Hotelier, Maj Hotel</p>
          </div>

          <div className="flex flex-col lg:flex-row">
            <div className="rounded-md text-lg text-gray-600 bg-white drop-shadow-md p-8 lg:mr-8 mb-8">
              <span className="bg-tertiary text-white rounded-full w-12 h-12 flex items-center justify-center drop-shadow-md shadow-purple-300 mb-4">
                <ThumbsUp className="fill-white w-6 h-6" />
              </span>

              <p className="mb-8">
                "I can't believe our guests can do all of this from a simple
                link on their phone."
              </p>

              <p>– Gary Selesner, President, Caesars Palace</p>
            </div>

            <div className="rounded-md text-lg text-gray-600 bg-white drop-shadow-md p-8">
              <span className="bg-tertiary text-white rounded-full w-12 h-12 flex items-center justify-center drop-shadow-md shadow-purple-300 mb-4">
                <Bolt className="fill-white w-6 h-6" />
              </span>

              <p className="mb-8">
                "Kohost is the best control system for building - their team and
                support is top notch!"
              </p>

              <p>– Tricia Wilbourne, Principal, Discovery</p>
            </div>
          </div>
        </section>

        <section className="container max-w-6xl mx-auto mb-32">
          <SectionHeading>
            Backed by incredible <Gradient>investors.</Gradient>
            <br /> Driven forward by <Gradient reverse>engineers.</Gradient>
          </SectionHeading>

          <div className="flex max-w-3xl mx-auto justify-center">
            <div className="flex-1 text-center p-4">
              <StaticImage
                src={"../images/ian-headshot.jpg"}
                className="w-36 mx-auto"
                alt="Ian Rogers"
              />
            </div>
            <div className="flex-1 text-center p-4">
              <StaticImage
                src={"../images/mark-headshot.jpeg"}
                className="w-36 mx-auto"
                alt="Mark Day"
              />
            </div>
            <div className="flex-1 text-center p-4">
              <StaticImage
                src={"../images/vadim-headshot.png"}
                className="w-36 mx-auto"
                alt="Vadim Fridman"
              />
            </div>
          </div>
        </section>

        <section className="container px-4 max-w-6xl mx-auto mb-32">
          <h3 className="font-bold text-3xl text-center mb-4">
            Want help designing a technology stack for your hotel?
          </h3>
          <p className="text-center text-xl text-gray-700 mb-8">
            Get discounts from our partner hospitality technologies like:
          </p>

          <div className="mb-16">
            <IntegrationGrid />
          </div>

          <SectionHeading>Want to partner with us?</SectionHeading>
          <p className="text-center">
            <Button onClick={() => setOpenModal(true)}>
              Grow my client base →
            </Button>
          </p>
        </section>
        <ContactModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          title="Contact Us"
        />
        <ContactForm hidden />
      </Layout>
    </>
  );
};

export default IndexPage;
