import React from "react";
import PhoneIcon from "../images/phone.inline.svg";
import BedIcon from "../images/bed.inline.svg";
import ChatIcon from "../images/chat.inline.svg";
import LightBulbIcon from "../images/lightbulb.inline.svg";
import RobotIcon from "../images/robot.inline.svg";
import Gradient from "./common/Gradient";
import Feature from "./common/AppFeature";

const features = [
  {
    icon: PhoneIcon,
    heading: "Mobile check-in",
    content: () => (
      <span>
        Stop manually checking I.D, copying credit cards, and signing
        registration cards at the front desk. Get your line{" "}
        <Gradient>down to zero. </Gradient>
      </span>
    ),
  },
  {
    icon: BedIcon,
    heading: "Self upsell",
    content: () => (
      <span>
        Remove all friction from the sale. Let your guests upgrade their room
        prior to arrival. <Gradient>Increase revpar</Gradient> without the
        awkward conversation.
      </span>
    ),
  },
  {
    icon: LightBulbIcon,
    heading: "Smart rooms",
    content: () => (
      <span>
        Save money by never ordering another key card. Save energy with
        lighting, media, climate, and shading automations.{" "}
        <Gradient>Delight your guests</Gradient> with welcome scenes and the
        ability to control their stay from their phone.
      </span>
    ),
  },
  {
    icon: ChatIcon,
    heading: "Guest communication",
    content: () => (
      <span>
        An AI powered digital concierge that communicates with your guests
        during <Gradient>every point of their stay.</Gradient> Extended stays?
        Room upgrades? With Kohost you can offer your guests anything, anytime.
      </span>
    ),
  },
  {
    icon: RobotIcon,
    heading: "Automation",
    content: () => (
      <span>
        Let Kohost verify I.D., tokenize payments and handle digital keys for
        your guests. <Gradient>Every single transaction</Gradient> is handled on
        our operating system for hotels from check-in to check-out.
      </span>
    ),
  },
];

const AppFeatures = () => {
  return (
    <div className="flex flex-col justify-center items-start lg:flex-row">
      {features.map((feature, idx) => {
        return <Feature key={idx + feature.heading} {...feature} />;
      })}
    </div>
  );
};

export default AppFeatures;
